@import "../vendors/mixins/bootstrap-breakpoints";
@import "../framework/mixins";

.navigation-banner-container {
  margin-top: 2rem;

  @include media-breakpoint-down(md) {
    margin-top: 3rem !important;
  }
}

.banner-item {
  padding-top: 1rem;
  color: #fff;

  &:first-child {
    padding-top: 1rem;
  }

  .banner-title {
    text-align: center;
    font-size: 1.7rem;
    font-weight: 400;
  }

  .banner-body {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 800px;
    margin: auto;
    margin-top: 1rem;

    .banner-illustration {
      svg {
        height: 150px;
        width: auto;
      }
    }

    .banner-points {
      li {
        font-size: 1.5rem;

        &:not(:first-child) {
          margin-top: 1rem;
        }
      }
    }
  }

  &.banner-global-delivery img,
  &.banner-tech-stack img,
  &.banner-engage svg {
    display: block;
    margin: auto;
  }

  &.banner-main {
    .datamorphosis-logo,
    .datamorphosis-title {
      display: block;
      height: auto;
      width: 60%;
      max-width: 350px;
      margin: auto;
    }

    .datamorphosis-title {
      margin-top: 2rem;
    }
  }

  &.banner-global-delivery {
    .banner-illustration img {
      height: 350px;
    }
  }

  &.banner-global-presence {
    .banner-illustration img {
      height: 410px;
    }
  }

  &.banner-tech-stack {
    .banner-illustration img {
      height: 350px;
    }
  }

  &.banner-engage {
    .banner-illustration svg {
      height: 300px;
    }
  }

  @include media-breakpoint-down(md) {
    &.banner-main {
      padding-top: 4rem;
    }

    &.banner-tech-stack {
      .banner-illustration img {
        max-width: 90%;
        height: auto;
      }
    }

    &.banner-engage {
      .banner-body .banner-illustration svg {
        max-width: 80%;
        height: auto;
      }
    }

    .banner-body {
      flex-direction: column;
      width: 80%;
      margin-top: 2rem;

      .banner-illustration {
        svg {
          height: 100px;
        }
      }

      .banner-points {
        margin-top: 1rem;

        li {
          font-size: 1rem;
          text-align: center;
        }
      }
    }
  }
}
