@function primary-color($color-name) {
  @return map-get($primary-colors, $color-name);
}

@function secondary-color($color-name) {
  @return map-get($secondary-colors, $color-name);
}

@function banner-background-color($index) {
  @return nth($banner-colors, $index);
}

@function banner-background-image($index) {
  @return nth($banner-images, $index);
}

@function heading-size($heading) {
  @return map-get($heading-sizes, $heading);
}

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
