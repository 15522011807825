@import "../vendors/mixins/bootstrap-breakpoints";
@import "../framework/mixins";

.articles-list {
  .article-card {
    position: relative;
    padding: 1rem;

    &:not(:first-child) {
      padding-top: 0.5rem;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 90%;
        height: 1px;
        margin: auto;
        background-color: secondary-color("dark-gray");
      }
    }

    .article-title {
      font-size: 1.3rem;
    }

    .article-date {
      font-size: 0.8rem;
      font-weight: 400;
      color: lighten(secondary-color("black"), 10%);
      margin: 5px 0;
    }

    .article-description {
      margin: 5px 0;
    }
  }

  @include media-breakpoint-down(sm) {
    .article-card {
      padding: 1rem 0.5rem;
    }
  }
}

.article-body {
  display: block;
  width: 80%;
  margin: 1rem auto 4rem;

  .article-body-header {
    border-bottom: 2px solid lighten(secondary-color("teal"), 70%);
  }

  .body-content {
    margin-top: 1rem;

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 10px;
    }

    a {
      color: lighten(secondary-color("teal"), 15%);

      &:hover {
        background-color: secondary-color("gray");
        border-bottom: 1px solid lighten(secondary-color("teal"), 15%);
      }
    }

    .service-logo {
      display: flex;
      justify-content: center;

      svg {
        width: 120px;
        height: auto;
        margin: auto;
        filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));

        * {
          fill: primary-color("blue");
        }
      }
    }
  }

  .book-service {
    display: table;
    margin: auto;
  }

  @include media-breakpoint-up(md) {
    width: 60%;
  }

  @include media-breakpoint-up(lg) {
    width: 55%;
  }

  @include media-breakpoint-up(xl) {
    width: 50%;
  }

  @include media-breakpoint-up(xxl) {
    width: 40%;
  }
}
