//
// Global Colors
//
$primary-colors: (
  "red": #e24414,
  "green": #7fb31a,
  "blue": #1ebee3,
  "orange": #ed8700,
  "pink": #dd80a2,
);

$secondary-colors: (
  "teal": #0c4b59,
  "light-gray": #f2f2f2,
  "dark-gray": #d9d9d9,
  "black": #444444,
);

$banner-colors: #062d33, #632814, #444, #16394d;
