//
// Site-wide Global Variables
//

// Twitter Bootstrap v4.5.2 Grid Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
  xxxxl: 1920px,
) !default;

$banner-count: 4;
$testimonials-count: 6;

$services-count: 12;
$services-col-count: 4;

$banner-images: "main.webp", "globe.webp", "globe2.webp", "techstack.webp";

$heading-sizes: (
  h1: 1.8rem,
  h2: 1rem,
  h3: 0.8rem,
  h4: 0.6rem,
  h5: 0.5rem,
  h6: 0.3rem,
);
