@import "./mixins";

.article-body {
  .article-title {
    line-height: 1.5;
    font-size: heading-size(h1);
    font-weight: bold;
  }

  h4.article-publish-date {
    font-size: 1rem;
    font-weight: 400;
    margin: 5px 0;
    color: lighten(secondary-color('black'), 10%);
    text-transform: uppercase;
  }

  p,
  ul,
  table {
    line-height: 1.5;
    font-size: 1rem;
    color: darken(secondary-color('black'), 5%);
  }

  p {
    @include font-smoothing;
  }

  blockquote {
    padding-left: 20px;
    border-left: 2px solid darken(secondary-color('light-gray'), 10%);

    p {
      font-size: 1.5em;
      font-style: italic;
      color: lighten(secondary-color('black'), 5%);
    }
  }

  ul {
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 40px;

    ul {
      list-style-type: circle;
      margin-block-start: 0.2em;
      margin-block-end: 0.2em;

      ul {
        list-style-type: square;
      }
    }
  }

  strong {
    color: darken(secondary-color('black'), 20%);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: secondary-color('black');
  }

  h2 {
    font-size: heading-size(h2);
  }

  h3 {
    font-size: heading-size(h3);
  }

  h4 {
    font-size: heading-size(h4);
  }

  h5 {
    font-size: heading-size(h5);
  }

  h6 {
    font-size: heading-size(h6);
  }

  em {
    margin-right: 3px;
  }

  table {
    width: 90%;
    margin: 50px auto;
    border-collapse: collapse;

    tbody {
      tr {
        &:nth-of-type(odd) {
          background-color: darken(secondary-color('light-gray'), 3%);
        }

        &:hover {
          background-color: darken(secondary-color('light-gray'), 5%);
        }
      }
    }

    th,
    td {
      padding: 0.5rem;
      text-align: left;
      border-top: 1px solid darken(secondary-color('light-gray'), 10%);
    }

    th {
      background-color: darken(secondary-color('light-gray'), 5%);
      border-bottom: 2px solid darken(secondary-color('light-gray'), 10%);
    }
  }

  a.centered {
    display: inline-block;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  img {
    &:not(.emoji) {
      display: block;
      margin: 50px auto;
      padding: 20px;
      max-width: 80%;
      background-color: darken(secondary-color('light-gray'), 2%);
      border: 1px solid darken(secondary-color('light-gray'), 7%);
      border-radius: 6px;
    }

    &.left-aligned {
      float: left;
      max-width: 40%;
      margin: 0;
      margin-right: 30px;
      margin-bottom: 10px;
    }

    &.no-border {
      padding: 0;
      border: 0;
    }

    &.filter-bw {
      filter: saturate(0);
      transition: filter 0.2s ease-in-out;

      &:hover {
        filter: saturate(1);
      }
    }
  }

  .language-sh,
  .language-shell,
  .language-bash {
    code {
      color: #bada55;
    }
  }

  .emoji {
    vertical-align: middle;
    width: 25px;
    height: 25px;
  }
}