@import "../vendors/mixins/bootstrap-breakpoints";
@import "../framework/mixins";

.navigation-wrapper {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .navigation-wrapper-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 1s ease;
    background-size: cover;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: 0;
    filter: blur(10px);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.5;
  }

  @for $i from 1 through $banner-count {
    &[data-current-banner="#{$i}"] {
      &,
      &::after {
        background-color: banner-background-color($i);
      }

      .navigation-wrapper-background {
        background-image: url("/assets/banners/#{banner-background-image($i)}");
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 0;
    padding-bottom: 1rem;
  }
}

.navigation-links-container {
  position: relative;
  display: flex;
  justify-content: center;
  transform: translateY(15px);
  transition: transform, background-color 0.1s ease;
  z-index: 300;

  &.afixed {
    position: fixed;
    width: 100%;
    transform: translateY(-16px);
    margin-top: 0;
    background-color: lighten(secondary-color("teal"), 5%);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);

    + .navigation-banner-container {
      margin-top: 8rem;
    }

    .navigation-links {
      box-shadow: none;
    }
  }

  .navigation-links {
    display: flex;
    align-items: center;
    background-color: lighten(secondary-color("teal"), 5%);
    border-radius: 4px;
    padding: 5px 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);

    .navigation-link-logo {
      display: flex;
      align-items: center;
      height: 100%;
      margin-left: 10px;
      margin-right: 10px;

      .datamorphosis-logo {
        width: 100px;
      }

      .datamorphosis-title {
        width: auto;
        height: 45px;
      }
    }

    .navigation-link-home {
      display: flex;
      align-items: center;
    }

    .navigation-link-item {
      position: relative;
      margin-right: 1rem;
      cursor: pointer;
      transition: background-color 0.2s;

      &:last-child {
        margin-right: 0;
      }

      .navigation-link {
        display: inline-block;
        position: relative;
        padding: 0.8rem 1rem;
        font-size: 1rem;
        color: #fff;
        text-transform: uppercase;
        transition: background-color 0.2s;
        border-radius: 4px;

        &:hover {
          background-color: primary-color("blue");
        }
      }
    }
  }

  .navigation-toggle {
    position: fixed;
    display: none;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    top: 10px;
    right: 15px;
    cursor: pointer;
    z-index: 300;

    &::before {
      position: absolute;
      left: -25%;
      top: -25%;
      width: 2px;
      height: 150%;
      transition: background 0.5s ease;
    }

    .navigation-toggle-logo {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 4px;
      background-color: #fff;
      transition: all 0.2s ease;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: #fff;
        transition: all 0.2s ease;
      }

      &::before {
        transform: translateY(12px);
      }

      &::after {
        transform: translateY(-12px);
      }
    }
  }

  .navigation-toggle-input {
    position: absolute;
    clip: rect(0, 0, 0, 0);

    &:checked {
      + .navigation-toggle {
        &::before {
          content: "";
        }

        .navigation-toggle-logo {
          background-color: transparent;

          &::before {
            transform: translateY(0);
            transform: rotate(-45deg);
          }

          &::after {
            transform: translateY(0);
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .navigation-links {
      .navigation-link-item .navigation-link {
        padding: 0.8rem 1rem;
        font-size: 1.1rem;
      }
    }
  }

  @include media-breakpoint-down(md) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 0;
    transform: translateY(0) !important;

    .navigation-toggle {
      display: flex;
    }

    .navigation-links {
      position: fixed;
      display: flex;
      flex-direction: column;
      top: 0;
      width: 100%;
      max-height: 65px;
      padding: 0;
      background-color: secondary-color("teal");
      border-bottom: 2px solid transparent;
      border-radius: 0;
      transition: max-height 0.3s ease;
      overflow: hidden;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4) !important;

      .navigation-link-logo {
        width: 100%;

        .datamorphosis-logo {
          width: 75px;
        }

        .datamorphosis-title {
          margin-top: 10px;
          margin-left: 10px;
        }
      }

      .navigation-link-item {
        margin-right: 0;

        &:first-child {
          margin-top: 4.5rem;
        }

        &:last-child {
          margin-bottom: 0.5rem;
        }

        &:hover {
          background-color: primary-color("blue");
        }

        .navigation-link:hover {
          background-color: transparent;
        }
      }
    }

    .navigation-toggle-input:checked ~ .navigation-links {
      max-height: 950px;
      transition: max-height 0.5s ease-out;
      border-bottom-color: darken(secondary-color("teal"), 2%);
    }
  }
}

.navigation-link-item {
  .navigation-sub-links {
    display: none;
    position: absolute;
    top: 100%;
    width: 200%;
    background-color: lighten(secondary-color("teal"), 5%);
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.4);
    border-radius: 4px;

    .navigation-sub-link {
      display: block;
      padding: 0.8rem 1rem;
      font-size: 1rem;
      color: #fff;
      text-transform: uppercase;
      transition: background-color 0.2s;
      border-radius: 4px;

      &:hover {
        background-color: primary-color("blue");
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &:hover .navigation-sub-links {
      &,
      &:hover {
        display: block;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .navigation-link:focus,
    .navigation-link.focus {
      & + .navigation-sub-links {
        display: block;
      }
    }

    .navigation-sub-links {
      position: relative;
      box-shadow: none;
      width: auto;
      text-align: center;
      border-radius: 0;
    }
  }
}
