@import "../vendors/mixins/bootstrap-breakpoints";
@import "./mixins";

.form {
  input[type="text"],
  input[type="date"],
  input[type="datetime"],
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="time"],
  input[type="url"],
  input[type="password"],
  textarea,
  select {
    box-sizing: border-box;
    outline: none;
    display: block;
    width: 100%;
    padding: 7px;
    border: none;
    background: transparent;
    font: 18px Arial, Helvetica, sans-serif;
  }

  textarea {
    resize: none;
    overflow: hidden;
  }

  label {
    display: inline-block;
    color: secondary-color("black");
  }
}

input[type="button"],
input[type="reset"],
input[type="submit"],
button,
a.btn {
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 14px;
  padding: 8px 18px;
  text-decoration: none;
  text-transform: uppercase;
}

input[type="submit"],
button.primary,
a.btn.primary {
  box-shadow: inset 0px 1px 0px 0px primary-color("blue");
  background-color: primary-color("blue");
  border: 1px solid primary-color("blue");

  &:hover {
    background: linear-gradient(
      to bottom,
      lighten(primary-color("blue"), 10%) 5%,
      primary-color("blue") 100%
    );
    background-color: primary-color("blue");
  }
}

input[type="button"],
input[type="reset"] {
  box-shadow: inset 0px 1px 0px 0px secondary-color("dark-gray");
  background-color: secondary-color("dark-gray");
  border: 1px solid secondary-color("dark-gray");

  &:hover {
    background: linear-gradient(
      to bottom,
      darken(secondary-color("dark-gray"), 10%) 5%,
      secondary-color("dark-gray") 100%
    );
    background-color: darken(secondary-color("dark-gray"), 10%);
  }
}
