@import "../vendors/mixins/bootstrap-breakpoints";
@import "../framework/mixins";

%animation-default {
  opacity: 1 !important;
  z-index: 3;
}

@mixin carousel($items, $type, $animation: "default") {
  .carousel__#{$type} {
    width: 100%;
    position: relative;
    overflow: hidden;

    > input[type="radio"] {
      position: absolute;
      left: 0;
      opacity: 0;
      top: 0;

      &:checked {
        ~ .carousel__items .carousel__item {
          @for $i from 1 through $items {
            &:nth-child(#{$i}) {
              opacity: 0;
            }
          }
        }
      }

      @for $i from 1 through $items {
        &:nth-child(#{$i}) {
          &:checked {
            ~ .carousel__items .carousel__item {
              @if $animation == "default" {
                &:nth-child(#{$i}) {
                  opacity: 1;
                }
              }
            }

            ~ .carousel__nav {
              > label {
                &:nth-child(#{$i}) {
                  background: #fff;
                  cursor: default;
                  pointer-events: none;
                }
              }

              &.dark > label {
                &:nth-child(#{$i}) {
                  background: secondary-color("black");
                }
              }
            }
          }
        }
      }
    }
  }

  .carousel__items {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;
    height: 500px;
    position: relative;
  }

  .carousel__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: opacity 1s;
  }

  .carousel__nav {
    position: absolute;
    bottom: 3%;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 3;

    > label {
      display: inline-block;
      width: 15px;
      height: 15px;
      margin: 0 2px;
      border: 1px solid #fff;
      border-radius: 2px;
      cursor: pointer;
    }

    &.dark > label {
      border-color: secondary-color("black");
    }
  }
}

@include carousel($banner-count, "banner");
@include carousel($testimonials-count, "testimonials");
