@import "../vendors/mixins/bootstrap-breakpoints";
@import "../framework/mixins";

.section-wrapper {
  padding-top: 2rem;
  padding-bottom: 4rem;

  .section-title {
    display: flex;
    justify-content: center;
    color: secondary-color("black");
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: uppercase;
  }

  .section-body {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    max-width: 800px;
    margin: auto;
    margin-top: 2rem;
    color: secondary-color("black");

    .section-item {
      position: relative;
      margin: 1rem;
    }

    .section-item-link {
      display: block;
      width: 165px;
      padding: 5px 10px;
      transition: background-color 0.2s ease;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: darken(secondary-color("light-gray"), 5%);
      }

      .section-item-logo svg,
      .section-item-title {
        display: block;
      }

      .section-item-logo {
        svg {
          width: 80px;
          height: auto;
          margin: auto;
          filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
        }
      }

      .section-item-title {
        margin-top: 1rem;
        text-align: center;
      }
    }
  }
}

.section-services {
  background-color: secondary-color("light-gray");

  .service-item {
    &::before,
    &::after {
      position: absolute;
      display: block;
      background-color: darken(secondary-color("light-gray"), 15%);
    }

    @for $i from 2 through $services-count {
      // Only show left border for items that are not
      // first or last.
      @if (($i % $services-col-count) != 1) {
        &:nth-child(#{$i}) {
          &::before {
            top: 10px;
            bottom: 0;
            left: -1rem;
            width: 1px;
            height: 75%;
          }
        }
      }
    }

    @for $i from 1 through ($services-count - $services-col-count) {
      &:nth-child(#{$i}) {
        &::after {
          left: 0;
          right: 0;
          bottom: -1rem;
          height: 1px;
          width: 80%;
          margin: auto;
        }
      }
    }
  }

  .service-link {
    .service-logo svg {
      * {
        fill: primary-color("orange");
      }
    }

    .service-title {
      color: darken(primary-color("orange"), 10%);
    }
  }

  @include media-breakpoint-up(lg) {
    .service-item {
      &::before,
      &::after {
        content: "";
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .service-link .service-logo svg {
      width: 50px !important;
    }
  }
}

.section-wrapper.section-customers {
  background-color: #ffffff;

  .section-body {
    width: 600px;
  }

  .customer-item {
    margin: 0.8rem;

    .customer-logo {
      display: flex;
      justify-content: center;

      img {
        width: 120px;
        max-height: 80px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .customers-container {
      max-width: 500px;
    }
  }

  @include media-breakpoint-down(xs) {
    .customers-container {
      max-width: 200px;
    }
  }
}

.section-about {
  background-color: secondary-color("light-gray");

  .section-body {
    padding: 1rem;
    text-align: left;
    max-width: 800px;
    font-size: 1.2rem;
    line-height: 1.5;
  }
}

.section-testimonials {
  padding-bottom: 2rem;

  .testimonials-list {
    height: 320px;

    .testimonial-item {
      font-size: 1.2rem;
      line-height: 1.5;
      background-color: #ffffff;
      padding: 1em;
      color: lighten(secondary-color("black"), 10%);

      p {
        margin: 0;
        text-align: center;
      }

      h3,
      h5 {
        text-align: center;
      }

      h3 {
        margin-top: 1em;
        margin-bottom: 0.2em;
      }

      h5 {
        margin-bottom: 1em;
      }
    }
  }
}

.section-footer {
  padding-bottom: 1rem;
  background-color: secondary-color("teal");

  .section-title,
  .footer-text {
    color: #fff;
    font-size: 1rem;
    text-align: center;
  }

  .footer-container {
    max-width: 700px;
    flex-direction: column;
  }

  .locations-list {
    width: 100%;
    justify-content: center;
    flex-flow: row wrap;

    &,
    .location-item {
      display: flex;
      align-items: center;
    }

    .location-item {
      margin: 0 10px;

      .location-marker-icon {
        width: 20px;
        margin-right: 5px;
      }

      .location-item-link {
        color: #fff;
        border-bottom: 1px dotted #fff;
        transition: all 0.2s ease;

        &:hover {
          color: secondary-color("light-gray");
          border-bottom-color: transparent;
        }
      }
    }
  }

  .partners-list {
    display: flex;
    align-items: center;
    margin: 2rem auto;

    svg {
      display: block;
      width: 80%;
      height: 75px;
      max-width: 200px;
      margin: auto;
    }

    .google-partner svg {
      width: auto;
    }
  }

  .contact-list {
    display: flex;
    align-items: center;
    margin: 1rem auto;

    li {
      width: 30px;
      height: 30px;

      &:not(last-child) {
        margin-right: 1rem;
      }

      svg {
        width: 100%;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .locations-list,
    .partners-list {
      flex-direction: column;
    }

    .partners-list li:not(:last-of-type) {
      margin-bottom: 1.5rem;
    }

    .footer-text {
      padding: 0 1rem;
      text-align: center;
    }
  }
}

.contact-body {
  $shadow: 0 2px 6px 0 rgba(123, 124, 122, 0.22),
    0 13px 12px -11px rgba(180, 181, 176, 0.32);

  display: flex;
  justify-content: space-evenly;
  margin-top: 3em;

  .contact-form,
  .contact-details {
    width: 400px;
    padding: 1em;
    font-family: sans-serif;
  }

  .contact-details {
    font-size: 1.2rem;
    line-height: 1.5;

    .map-embed {
      height: 300px;
      width: auto;
      margin-top: 2em;
      border: 1px solid secondary-color("dark-gray");
      box-shadow: $shadow;

      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }

  .field {
    padding: 0.5rem 0.5rem;
    border: 1px solid secondary-color("dark-gray");
    box-shadow: $shadow;

    label,
    input {
      transition: transform 0.3s ease;
    }

    label {
      transform: translateX(25%) translateY(90%) scale(130%);
    }

    input {
      transform: translateY(-50%) scaleY(200%);
    }

    textarea {
      transform: scaleY(125%);
    }

    &:focus-within {
      label,
      input {
        transform: translateY(0) scale(100%);
      }

      label {
        transform: translateX(0);
      }

      textarea {
        transform: scaleY(100%);
      }
    }

    & + .field {
      margin-top: 2rem;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    margin-top: 2em;

    input + input {
      margin-top: 1em;
    }

    input {
      padding: 10px 18px;
      font-size: 18px;

      &,
      &:hover {
        transition: all 0.3s ease;
      }
    }
  }

  @include media-breakpoint-down(md) {
    display: block;

    .contact-form,
    .contact-details {
      margin: auto;
    }
  }

  @include media-breakpoint-down(sm) {
    .contact-form,
    .contact-details {
      width: auto;
      max-width: 400px;
    }
  }
}
